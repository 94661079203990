import React from "react";
import { motion } from "framer-motion";

import YourSVGComponent from "./customSvg";

function FrontPageImage({ picture, credits, secondCredit }) {
  return (
    <div
      style={{
        maxWidth: "100%",
        width: "100vw",
        height: "100vh",
        background: `url(/${picture})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <img
        className="first-page-logo"
        src="/deep_nic_yellow.png"
        alt="DeepNic Logo Yellow"
      ></img>

      <div className="map-button-container">
        <div className="store-links">
          <a
            href="https://apps.apple.com/gr/app/deepnic/id6478768091?l=el"
            target="_blank"
            rel="noopener noreferrer"
            className="store-link"
          >
            <img src="/app_store.svg" alt="App Store" className="store-icon" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=cy.org.cyens.deepnic"
            target="_blank"
            rel="noopener noreferrer"
            className="store-link"
          >
            <img src="/google_play.svg" alt="Google Play Store" className="store-icon" />
          </a>
        </div>

        <a href="/selection">
          <YourSVGComponent
            classNam={"go-to-map-button"}
          />
        </a>
      </div>

      <div className="picture-credits">
        {credits == "" ? null : credits}
        {secondCredit == "" ? null : (
          <>
            <br></br>
            <span style={{ fontSize: 10 }}>{secondCredit}</span>
          </>
        )}
      </div>
    </div>
  );
}

export default FrontPageImage;
